import { useState, useEffect } from 'react';

export const useIsSP = () => {
    const [isMobile, setIsMobile] = useState(false);
  
    useEffect(() => {
      const checkIfMobile = () => {
        const userAgent = navigator.userAgent;
        const isScreenWidthSmall = window.innerWidth <= 450;
        setIsMobile(isScreenWidthSmall);
      };
  
      // Check on initial render
      checkIfMobile();
  
      // Check when the window is resized
      window.addEventListener('resize', checkIfMobile);
  
      // Cleanup event listener on component unmount
      return () => window.removeEventListener('resize', checkIfMobile);
    }, []);
  
    return isMobile;
  };