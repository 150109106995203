import { useState, useEffect } from 'react';
import { useIsMobile } from './isMobile';

// スマホ、IPad(PC)ディスプレイにおける基準値 に対する画面の比率を計算する
const basicWidth_SP = 400
const basicWidth_PC = 1024

// スマホのwidth最大値+20くらいの値でSP判定を行う
const maxWidth_SP = 430 + 20
// モバイル端末の最大+20くらいの値でSP判定を行う
const maxWidth_Mobile = 1024 + 20

export const useFlexRatio = () => {
    const [isSP, setIsSP] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    // iPad系統をPCとみなしたときのratio
    const [ratio_iPad_PC, setRatioIpadPC] = useState(window.innerWidth / (isSP ? basicWidth_SP : basicWidth_PC));
    const [ratio_iPad_SP, setRatioIpadSP] = useState(window.innerWidth / (isMobile ? basicWidth_SP : basicWidth_PC));

    useEffect(() => {
        const update = () => {
            const isScreenWidthSmall_SP = window.innerWidth <= maxWidth_SP;
            const isScreenWidthSmall_Mobile = window.innerWidth <= maxWidth_Mobile;
            setIsSP(isScreenWidthSmall_SP);
            setIsMobile(isScreenWidthSmall_Mobile)
            setRatioIpadPC(window.innerWidth / (isScreenWidthSmall_SP ? basicWidth_SP : basicWidth_PC));
            setRatioIpadSP(window.innerWidth / (isScreenWidthSmall_Mobile ? basicWidth_SP : basicWidth_PC));
        };
        update();
        window.addEventListener('resize', update);
        return () => window.removeEventListener('resize', update);
    }, []);

    return {ratio_iPad_PC: ratio_iPad_PC, ratio_iPad_SP:ratio_iPad_SP, isSP: isSP, isMobile: isMobile};
};