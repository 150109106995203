import React, { useState, useEffect, useContext } from 'react';
import { Stack, Typography, Link, Divider } from '@mui/material';
import reidea_logo from '../img/header_icon.svg'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CopyrightIcon from '@mui/icons-material/Copyright';
import { useIsMobile } from './isMobile';
import FooterSP from './reidea_footer_sp';
import { GetCategories } from './API/categories';
import { MyContext } from '../routes/router';

const linkStyle = {
//   color: '#5C5C5C',
  color: 'white',
  fontSize: '18px',
  textAlign: 'left',
};

const linkStyle1 = {
    //   color: '#5C5C5C',
      color: 'white',
      fontSize: '14px',
      textAlign: 'left',
    };

function Footer(){
  const queryString = (category:any) => {
    const query_url = new URLSearchParams({ categories: JSON.stringify([category]) }).toString();
    return "/search?mode=apply&" + query_url
  }
  const { data: categories, isValid: isValid_categories, setIsValidData: setIsValidData_categories }  = GetCategories();

  const {minWidth} =useContext(MyContext)
  //モバイル端末かどうかを判定
  const isMobile = useIsMobile();

  if(categories == null){
    return null;
  }

  return(
    isMobile ? <FooterSP/> :
    // <Stack sx={{ background: `#E8E8E8`, width: '100%'}}
    <Stack sx={{ background: `#383E86`, width: '100%'}}
        height='340px'
        width={'100vm'}
        display={'flex'}
        alignItems={'center'}
        direction={'column'}
        justifyContent="space-between"
        minWidth={minWidth}
        >
        <Stack direction={'row'} justifyContent={'space-between'} width={'100%'} my={5} px={12}>
        <Stack spacing={"16px"}>
            <Stack>
                <Typography sx={{...linkStyle, fontWeight: '700'}}>カテゴリ一覧</Typography>
                <Divider sx={{borderColor:'white', width: '100%'}}/>
            </Stack>
            <Stack direction={'row'} justifyContent={'space-between'} spacing={4}>
                <Stack alignItems={"flex-start"} spacing={1}>
                    {categories.slice(0,5).map((category:{category_id:number, name:string}, index:number) => {
                        return(
                            <Link href={queryString(category.name)} underline='hover' sx={linkStyle1}>{category.name}</Link>
                        )
                    })}
                </Stack>
                <Stack alignItems={"flex-start"} spacing={1}>
                    {categories.slice(5,10).map((category:{category_id:number, name:string}, index:number) => {
                        return(
                            <Link href={queryString(category.name)} underline='hover' sx={linkStyle1}>{category.name}</Link>
                        )
                    })}
                </Stack>
                <Stack alignItems={"flex-start"} spacing={1}>
                    {categories.slice(10,15).map((category:{category_id:number, name:string}, index:number) => {
                        return(
                            <Link href={queryString(category.name)} underline='hover' sx={linkStyle1}>{category.name}</Link>
                        )
                    })}
                </Stack>
            </Stack>
        </Stack>

        <Stack spacing={3}>
                <Typography fontSize={'40px'} sx={{color:'white', fontWeight:800}}>RE-IDEA</Typography>
                <Stack  spacing={1}>
                    <Stack  direction={'row'} alignItems='center'>
                        <ArrowForwardIosIcon sx={{ color: 'white', fontSize: '12px'}} />
                        <Link href="/privacyPolicy" underline='hover' sx={linkStyle1}>プライバシーポリシー</Link>
                    </Stack>
                    <Stack  direction={'row'} alignItems='center'>
                        <ArrowForwardIosIcon sx={{ color: 'white', fontSize: '12px'}} />
                        <Link href="/specifiedCommercialTransaction" underline='hover' sx={linkStyle1}>特定商取引法に関する記載/運営会社</Link>
                    </Stack>
                    <Stack  direction={'row'} alignItems='center'>
                        <ArrowForwardIosIcon sx={{ color: 'white', fontSize: '12px'}} />
                        <Link href="/userAgreement" underline='hover' sx={linkStyle1}>利用規約</Link>
                    </Stack>
                </Stack>
        </Stack>
        </Stack>

        <Stack direction={'row'} paddingY={2} spacing={1}>
        <Typography sx={{color:'#383E86', fontSize: '14px'}}>Copyright</Typography>
        <CopyrightIcon  sx={{color:'#383E86'}}/>
        <Typography sx={{color:'#383E86', fontSize: '14px'}}>RE-IDEA All rights reserved.</Typography>
        </Stack>

    </Stack>
  )
}

export default Footer;