import React, { useState, useEffect, useContext } from 'react';
import { Grid,Stack, FormGroup, Checkbox, Card, Button, TextField, Typography,InputAdornment, Box } from '@mui/material';
import { useIsMobile } from '../components/isMobile';
import { MyContext } from '../routes/router';
import MoreButton from '../components/Buttons/more';
import { useSearchParams } from "react-router-dom";
import { usePutUser } from '../components/API/users';
import { AuthenticateCode } from '../components/API/code';
import { Password } from '@mui/icons-material';


const passErrorCheck = (text:string) => {
    const regex = /^(?=.*[a-z])(?=.*\d).{6,}$/;
    if (!regex.test(text)) {
        return true;
    }
    return false;
    };



function SetPassword() {
  const isMobile = useIsMobile()
  const [searchParams] = useSearchParams();
  // codeにより認証を行う
  const code = searchParams.get("code");
  const userID = searchParams.get("userID");
  const [authenticate, setAuthenticate] = useState<boolean>(false);
  const [new_password, setNewPassword] = useState<string>("");
  const [password_error, setPasswordError] = useState<boolean>(false);
  const [_isLoading, _setIsLoading] = useState<boolean>(false);
  const mutatePutUser = usePutUser()

  React.useEffect(()=>{
    AuthenticateCode(code ?? "", ()=>{setAuthenticate(true)}, ()=>{setAuthenticate(false)})
  }, [])

  const endLoading = useContext(MyContext).endLoading 
  endLoading()


  return (
    <Stack  minHeight={isMobile ? 500 : 600} minWidth={isMobile ? 400 : 1000} justifyContent={"center"} alignItems={"center"} sx={{backgroundColor:"#F6F8FA"}}>
        <Stack width={isMobile ? "80%" : "30%"} spacing={6} p={5} minHeight={"400px"} alignItems={"center"} sx={{backgroundColor: "white", borderRadius:"20px" }}>
            <Typography sx={{fontWeight:"bold", color:"#363636", fontSize: isMobile ? 18 : 24}}>
                {authenticate === false ? "再設定用のリンクの有効期限が切れている可能性があります。" : "パスワードの再設定"}
            </Typography>
            {authenticate === false ?
                <Typography sx={{fontWeight:"bold", color:"#363636", fontSize: 32}}>
                    404
                </Typography>
            :
                <Stack>
                    <TextField 
                        disabled={_isLoading}
                        placeholder='新しいパスワードを入力' 
                        sx={{width:"240px", backgroundColor:"white", borderRadius:"10px",
                        '& .MuiOutlinedInput-root': {
                            paddingRight: "0px",
                            '& fieldset': {
                                border: "none",
                                borderRadius: '10px',
                            },
                        },
        
                        "& .MuiInputBase-multiline ":{
                            padding: "0px 0px", // マルチラインの場合、余白をリセットする
                        },
                        '&:hover .MuiOutlinedInput-notchedOutline': {border: "none", },
                        '& .Mui-focused .MuiOutlinedInput-notchedOutline': { border: "none"},
                        }}
                        value={new_password}
                        required
                        onChange={(event) => {setNewPassword(event.target.value); setPasswordError(passErrorCheck(event.target.value))}}
                        inputProps={{style: {fontSize: 14, padding:10,borderRadius:"5px",  border:password_error ? "1px solid red" : "0.5px solid grey"}}}/>
                    {password_error &&
                        <Typography textAlign={"right"} sx={{fontWeight:"bold", color:"red", fontSize: 12}}>
                            【半角英数6文字以上】例. xasyq1234
                        </Typography>
                    }
                </Stack>
            }

            <Typography sx={{fontWeight:"bold", color:"#363636", fontSize: isMobile ? 12 : 14}}>
                {authenticate === false ? "誠に申し訳ございませんが、再度パスワードの再設定を行っていただけますようお願い申し上げます。" : "新しいパスワードを入力し、再設定を行っていただけますようお願い申し上げます。"}
            </Typography>

            <MoreButton loading={_isLoading} text='再設定する' fontSize='18px' width='180px' height='36px' onClick={()=>{ _setIsLoading(true); 
                mutatePutUser.mutate({user_id: userID, newValues:{Password: new_password}},
                    {onSuccess: () =>{_setIsLoading(false); window.location.href="/?login=true"},
                     onError: () =>{_setIsLoading(false);setPasswordError(true)}})}}/>
            
        </Stack>
    </Stack>
  )

}

export default SetPassword;
