import React, { useState, useEffect } from 'react';
import { Stack, Typography, Link, Divider } from '@mui/material';
import reidea_logo from "./../img/RE-IDEA.png"
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CopyrightIcon from '@mui/icons-material/Copyright';
import { GetCategories } from './API/categories';

const linkStyle = {
    color: 'white',
    fontSize: '14px',
    textAlign: 'left',
};


const categoryStyle = {
    color: 'white',
    fontSize: '13px',
    textAlign: 'left',
};

function FooterSP(){
  const queryString = (category:any) => {
    const query_url = new URLSearchParams({ categories: JSON.stringify([category]) }).toString();
    return "/search?mode=apply&" + query_url
  }
  const { data: categories, isValid: isValid_categories, setIsValidData: setIsValidData_categories }  = GetCategories();
  
  if(categories == null){
    return null;
  }

  return(
    // <Stack sx={{ background: `#E8E8E8`, width: '100%'}}
    <Stack sx={{ background: `#383E86`, width: '100%'}}
        display={'flex'}
        alignItems={'flex-start'}
        direction={'column'}
        justifyContent="space-between"
        // minWidth={"320px"}
        minHeight={'550px'}
        px={5}
        pt={5}
        pb={2}
        spacing={2}
        >
        <Typography fontSize={'30px'} sx={{color:'white', fontWeight:800}}>RE-IDEA</Typography>

        <Divider sx={{borderColor:'white', width: '100%'}}/>

        <Stack spacing={"16px"} width={'100%'}>
            <Typography sx={{...linkStyle, fontWeight: '700'}}>カテゴリ一覧</Typography>
            <Stack direction={'row'} justifyContent={'space-between'}>
                <Stack alignItems={"flex-start"} spacing={1}>
                    {categories.slice(0,5).map((category:{category_id:number, name:string}, index:number) => {
                        return(
                            <Link href={queryString(category.name)} underline='hover' sx={linkStyle}>{category.name}</Link>
                        )
                    })}
                </Stack>
                <Stack alignItems={"flex-start"} spacing={1}>
                    {categories.slice(5,10).map((category:{category_id:number, name:string}, index:number) => {
                        return(
                            <Link href={queryString(category.name)} underline='hover' sx={categoryStyle}>{category.name}</Link>
                        )
                    })}
                </Stack>
                <Stack alignItems={"flex-start"} spacing={1}>
                    {categories.slice(10,15).map((category:{category_id:number, name:string}, index:number) => {
                        return(
                            <Link href={queryString(category.name)} underline='hover' sx={linkStyle}>{category.name}</Link>
                        )
                    })}
                </Stack>
            </Stack>
        </Stack>

        <Divider sx={{borderColor:'white', width: '100%'}}/>

        <Stack spacing={1}>
            <Stack  direction={'row'} alignItems='center' spacing={1}>
                <ArrowForwardIosIcon sx={{ color: 'white', fontSize: '10px'}} />
                <Link href="/privacyPolicy" underline='hover' sx={linkStyle}>プライバシーポリシー</Link>
            </Stack>
            <Stack  direction={'row'} alignItems='center' spacing={1}>
                <ArrowForwardIosIcon sx={{ color: 'white', fontSize: '10px'}} />
                <Link href="/specifiedCommercialTransaction" underline='hover' sx={linkStyle}>特定商取引法に関する記載/運営会社</Link>
            </Stack>
            <Stack  direction={'row'} alignItems='center' spacing={1}>
                <ArrowForwardIosIcon sx={{ color: 'white', fontSize: '10px'}} />
                <Link href="/userAgreement" underline='hover' sx={linkStyle}>利用規約</Link>
            </Stack>
        </Stack>


        <Stack direction={'row'} spacing={0.2} display={"flex"} alignItems={"center"} justifyContent={"center"} alignSelf={'center'}>
            <Typography sx={{color:'white', fontSize: '8px'}}>Copyright</Typography>
            <Stack display={"flex"} alignItems={"center"} justifyContent={"center"}>
                <CopyrightIcon sx={{fontSize:'10px', color:'white'}}/>
            </Stack>
            <Typography sx={{color:'white', fontSize: '10px'}}>RE-IDEA All rights reserved.</Typography>
        </Stack>

    </Stack>
  )
}

export default FooterSP;