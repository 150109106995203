import { Box, Typography, Card, Stack, Avatar} from "@mui/material";
import React, { useEffect } from "react";
import { useState } from 'react';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';


export interface IdeaCardProps {
    profile_image_name?: string | null | undefined;
    profile_image_uri?: string | null | undefined;
    user_name: string;
    onClick: () => void;
    ratio?: number;
    radius: number;
    setAnchorEL: (element: HTMLElement | null) => void;
}

const UserIconAnchored: React.FC<IdeaCardProps> = (props: IdeaCardProps) => {

    const [isHovered, setIsHovered] = useState(false);


    const handleMouseEnter  = (event: React.MouseEvent<HTMLElement>) => {
        props.setAnchorEL(event.currentTarget);
    };


    let ratio = 1;
    if(props.ratio != (null || undefined)){
        ratio = props.ratio;
    }
    
  return (
    props.profile_image_name != null  ? 
        <img onClick={handleMouseEnter} src={process.env.REACT_APP_PUBLIC_URL+"/images/" + props.profile_image_name} style={{ borderRadius: props.radius * ratio, width: props.radius * 2 *ratio, height: props.radius * 2 * ratio, cursor:"pointer",objectFit: "cover",objectPosition: "center"}}/>
    :
    props.profile_image_uri != null  ? 
        <img aria-owns='mouse-over-popover' aria-haspopup="true" 
             onClick={handleMouseEnter} src={props.profile_image_uri} style={{ borderRadius: props.radius * ratio, width: props.radius * 2 *ratio, height: props.radius * 2 * ratio, cursor:"pointer",objectFit: "cover",objectPosition: "center"}}/>
    :   <Avatar onClick={handleMouseEnter} sx={{width: props.radius * 2 * ratio, height: props.radius * 2 * ratio, fontSize: props.radius *ratio, cursor:"pointer"}}>{props.user_name ? props.user_name[0] : ""}</Avatar>
  );
};

export default UserIconAnchored;
