import React, { useState, useEffect, useContext, useRef } from 'react';
import Box from '@mui/material/Box';
import { InputAdornment, IconButton, Typography, Button, Modal, Stack, TextField, Card, Avatar } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSearchParams } from "react-router-dom";

import CloseIcon from '@mui/icons-material/Close';
import Fade from '@mui/material/Fade';
import Divider from '@mui/material/Divider';
import appleIcon from "./../../img/apple_icon.png"
import googleIcon from "./../../img/google_icon.png"
import lineIcon from "./../../img/line_icon.png"
import TaskAltIcon from '@mui/icons-material/TaskAlt';

import Zoom from '@mui/material/Zoom';
import { useLogin } from '../API/users';
import { MyContext } from '../../routes/router';
import PopupLoginError from './snsLoginError';
import PopupSetNewPassword from './setNewPassword';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 700,
    height: 440,
    overflowY: "auto",
    backgroundColor: 'white',
    justifyContent: "center",
    alignItems: "center",
    borderRadius:5
};
const styleSP = {
    position: 'absolute',

    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "85%",
    height: 550,
    // overflowY: "scroll",
    backgroundColor: 'white',
    justifyContent: "center",
    alignItems: "center",
    borderRadius:5,
};

// Propsの型を定義
interface Props {
    handleClose: () => void;
    open: boolean;
    isMobile?: boolean;
    handleOpenRegister?: () => void
    setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  }

  const PopupLoginForm: React.FC<Props> = ({ handleClose, open, setOpen, isMobile, handleOpenRegister }) => {
   
    const [searchParams] = useSearchParams();
    const [mail, setMail] = useState("")
    const [password, setPassword] = useState("")

    // lineIDによりエラーハンドリングを行う
    const loginID = searchParams.get("loginID");

    // URLからの強制展開
    const login = searchParams.get("login");
    const [showRegisterErrorPopup, setShowRegisterErrorPopup] = useState<boolean>(false);
    const [showSetNewPasswordPopup, setShowSetNewPasswordPopup] = useState<boolean>(false);

    const socket = useContext(MyContext).socket

    const [isLogin, setIsLogin] = useState<boolean>(false)
    const [login_error, setLoginError] = useState<boolean>(false)

    const login_mutation = useLogin(()=>{setIsLogin(true);}, ()=>{setLoginError(true)}, mail, password, socket.id);
                                                        

    useEffect(() => {
        if(login === "true" && setOpen){setOpen(true);}
        if(loginID === ""){
            if(setOpen) setOpen(false);
            setShowRegisterErrorPopup(true)
        }
    }, []);


    const cleanInputValues=()=>{
        setMail('');
        setPassword('');
    }

  return (
    <>
        <Modal open={open} onClose={handleClose} sx={{border:"none"}}>
            <Fade in={open}>
                <Stack sx={isMobile ? styleSP : style}>
                    <Zoom in={isLogin}  style={{position:'absolute', transitionDelay: isLogin ? '500ms' : '0ms' }}>
                        <Stack width={'90%'} height={'85%'} justifyContent={"center"} alignItems={"center"} sx={{position:"relative", borderRadius:"7px", backgroundColor:"whitesmoke"}} pb={3}>
                            <TaskAltIcon sx={{fontSize:100, color:"lightgreen"}}/>
                            <Typography textAlign={"center"}  component="div" width={"100%"} sx={{margin:"40px auto 20px auto", letterSpacing: '0px'}}>
                                <Box sx={{color: "#2B2B2B", fontSize:20, fontWeight: 'bold',  }}>
                                    ログインできました
                                </Box>
                            </Typography>
                        </Stack>
                    </Zoom>
                    <div style={{ position: 'absolute', top: 0, right: 0 }}>
                        <IconButton onClick={()=>{handleClose(); cleanInputValues();}}>
                            <CloseIcon  sx={{fontSize:24}} />
                        </IconButton>
                    </div>

                    <Fade in={!isLogin}>
                        <Stack justifyContent={"flex-start"} alignItems={"center"}  sx={{backgroundColor:'whitesmoke', borderRadius: "7px", overflow: 'scroll'}} width={'90%'} height={"88%"} py={1}>
                            <Typography textAlign={"center"} width={"100%"} sx={{mb:'5px' ,letterSpacing: '0px'}}>
                                <Box sx={{color: "#2B2B2B", fontSize:20, fontWeight: 'bold',  }}>
                                    ログイン
                                </Box>
                            </Typography>
                            
                            <Stack height={"100%"} direction={isMobile ? "column" : "row"} justifyContent={"center"} alignItems={"center"} spacing={4}>
                                {/* メールアドレスとパスワード */}
                                <Stack direction={"column"} width={"100%"} alignItems={"center"}>
                                    <Stack>
                                        <Typography component="div" width={"100%"} sx={{margin:"5px auto", letterSpacing: '0px'}}>
                                            <Box sx={{  color: "grey", fontSize:12, fontWeight: 'bold',  }}>
                                                メールアドレス
                                            </Box>
                                        </Typography>
                                        <TextField 
                                            placeholder='メールアドレスを入力' 
                                            sx={{width:"250px", backgroundColor:"white", borderRadius:"10px"}}
                                            value={mail}
                                            required
                                            onChange={(event) => {setMail(event.target.value); setLoginError(false)}}
                                            inputProps={{style: {fontSize: 12, padding:10,borderRadius:"5px", border:login_error ? "1px solid red" : "none"}}}/>
                                    </Stack>
                                    <Stack mt={1}>
                                        <Typography component="div" width={"100%"} sx={{margin:"5px auto", letterSpacing: '0px'}}>
                                            <Box sx={{color: "grey", fontSize:12, fontWeight: 'bold',  }}>
                                                パスワード
                                            </Box>
                                        </Typography>
                                        <TextField 
                                            placeholder='パスワードを入力' 
                                            sx={{width:"250px", backgroundColor:"white", borderRadius:"10px"}}
                                            value={password}
                                            type='password'
                                            required
                                            onChange={(event) => {setPassword(event.target.value); setLoginError(false)}}
                                            inputProps={{style: {fontSize: 12, padding:10,borderRadius:"5px", border:login_error ? "1px solid red" : "none"}}}/>
                                        {login_error && <Typography component="div" width={"100%"} textAlign={"right"} sx={{margin:"5px auto", letterSpacing: '0px'}}>
                                            <Box sx={{  color: "red", fontSize:10, fontWeight: 'medium',  }}>
                                                メールアドレスまたはパスワードが間違っています
                                            </Box>
                                        </Typography>
                                        }
                                    </Stack>

                                    <Typography textAlign="right" component="div" sx={{width:"250px", margin:"5px auto 2px auto", letterSpacing: '0px'}}>
                                        <Box onClick={()=>{ handleClose(); setShowSetNewPasswordPopup(true)}} sx={{ cursor:"pointer", ":hover":{textDecoration:"underline"}, color: "dodgerblue", fontSize:10, fontWeight: 'bold',  }}>
                                            パスワードをお忘れの方
                                        </Box>
                                    </Typography>
                                    <Typography textAlign="right" component="div" sx={{width:"250px", margin:"2px auto", letterSpacing: '0px'}}>
                                        <Box onClick={()=>{if(handleOpenRegister) handleOpenRegister()}} sx={{ cursor:"pointer", ":hover":{textDecoration:"underline"}, color: "dodgerblue", fontSize:10, fontWeight: 'bold',  }}>
                                            初めてご利用の方(新規登録)
                                        </Box>
                                    </Typography>

                                    <Stack mt={2} width={"250px"}>
                                        <Button 
                                            onClick={async () => {
                                                await login_mutation.mutate();
                                            }}  variant='contained' sx={{ fontSize:12, padding:"5px", width:"100%", borderRadius:"20px", backgroundColor: "#383E86", ":hover":{backgroundColor: "#383E86",}}}>
                                            ログイン
                                        </Button>
                                    </Stack>
                                </Stack>

                                {isMobile === false &&
                                    <Divider orientation={"vertical"} sx={{ height:"80%", backgroundColor:"gainsbolo"}}/>
                                }

                                {/* 各種SNSログイン */}
                                <Stack direction={"column"} justifyContent={"center"} alignItems={"center"} width={"100%"} spacing={2}>
                                    <Button onClick={() => {window.location.href=`${process.env.REACT_APP_OFFICIAL_API_URL}/login/auth/google?originalURL=${encodeURIComponent(window.location.href)}`;}} variant='contained' sx={{boxShadow:"none", border:"0.5px solid lightgrey", width:250, fontSize:12, padding:"5px", borderRadius:"20px", color:"#2B2B2B", backgroundColor: "white", ":hover":{backgroundColor: "snow",}}}>  
                                        <Typography component="div" textAlign={"center"} width={"100%"} sx={{margin:"5px auto", letterSpacing: '0px', textTransform:"none"}}>
                                            <Stack direction={"row"} justifyContent={"center"} alignItems={"center"} sx={{position:"relative", color: "#2B2B2B", fontSize:12, fontWeight: 'bold',  }}>
                                                <img style={{position:"absolute", left:10}} src={googleIcon} width={"30px"}/>Googleでログイン
                                            </Stack>
                                        </Typography>
                                    </Button>
                                    <Button onClick={() => {window.location.href=`${process.env.REACT_APP_OFFICIAL_API_URL}/login/auth/line?originalURL=${encodeURIComponent(window.location.href)}`;}} variant='contained' sx={{boxShadow:"none", border:"0.5px solid lightgrey", width:250, fontSize:12, padding:"5px", borderRadius:"20px", color:"#2B2B2B", backgroundColor: "white", ":hover":{backgroundColor: "snow",}}}>  
                                        <Typography component="div" textAlign={"center"} width={"100%"} sx={{margin:"5px auto", letterSpacing: '0px', textTransform:"none"}}>
                                            <Stack direction={"row"} justifyContent={"center"} alignItems={"center"} sx={{position:"relative", color: "#2B2B2B", fontSize:12, fontWeight: 'bold',  }}>
                                                <img style={{position:"absolute", left:10}} src={lineIcon} width={"30px"}/>Lineでログイン
                                            </Stack>
                                        </Typography>
                                    </Button>
                                    <Button disabled variant='contained' sx={{boxShadow:"none", border:"0.5px solid lightgrey", width:250, fontSize:12, padding:"5px", borderRadius:"20px", color:"#2B2B2B", backgroundColor: "white", ":hover":{backgroundColor: "snow",}}}>  
                                        <Typography component="div" textAlign={"center"} width={"100%"} sx={{margin:"5px auto", letterSpacing: '0px', textTransform:"none"}}>
                                            <Stack direction={"row"} justifyContent={"center"} alignItems={"center"} sx={{position:"relative", color: "#2B2B2B", fontSize:12, fontWeight: 'bold',  }}>
                                                <img style={{position:"absolute", left:10}} src={appleIcon} width={"30px"}/>Appleでログイン(準備中)
                                            </Stack>
                                        </Typography>
                                    </Button>
                                </Stack>
                            </Stack>
                        </Stack>
                    </Fade>
                </Stack>
            </Fade>
        </Modal>
        <PopupLoginError open={showRegisterErrorPopup} handleClose={()=>{setShowRegisterErrorPopup(false)}} isMobile={isMobile} />
        <PopupSetNewPassword open={showSetNewPasswordPopup} handleClose={()=>{setShowSetNewPasswordPopup(false)}} isMobile={isMobile} />
    </>
  );
};

export default PopupLoginForm;