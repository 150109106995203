import React, { useState, useEffect, useContext } from 'react';
import { Grid,Stack, FormGroup, Checkbox, Card, Button, TextField, Typography,InputAdornment, Box } from '@mui/material';
import { useIsMobile } from '../components/isMobile';
import { MyContext } from '../routes/router';
import MoreButton from '../components/Buttons/more';

function NotFound() {
    const isMobile = useIsMobile()


  const endLoading = useContext(MyContext).endLoading 
  endLoading()
  
  return (
    <Stack  minHeight={isMobile ? 500 : 600} justifyContent={"center"} alignItems={"center"} sx={{backgroundColor:"#F6F8FA"}}>
        <Stack width={isMobile ? "80%" : "30%"} spacing={6} p={5} minHeight={"400px"} alignItems={"center"} sx={{backgroundColor: "white", borderRadius:"20px" }}>
            <Typography sx={{fontWeight:"bold", color:"#363636", fontSize: 18}}>
                このページは存在しません
            </Typography>

            <Typography sx={{fontWeight:"bold", color:"#363636", fontSize: 32}}>
                404
            </Typography>

            <Typography sx={{fontWeight:"bold", color:"#363636", fontSize: 12}}>
                誠に申し訳ございませんが、お探しの情報は掲載期間が終了しているかログイン中のアカウントではアクセスできないなどの理由によりご覧いただけません。お手数ですが、ログイン状況などをお確かめください。
            </Typography>

            <MoreButton text='TOPへ' fontSize='18px' width='120px' height='28px' onClick={()=>{window.location.href="/"}}/>
            
        </Stack>
    </Stack>
  )

}

export default NotFound;
