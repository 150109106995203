import React, { useState, useEffect } from 'react';
import axios from 'axios';

// メールアドレスに認証メールを送信する
export const PostCodeByMail = (mail:string) => {
    axios.post(process.env.REACT_APP_API_URL+'/register/sendcodebymail', {mail: mail})
        .then(res => {
        }).catch((err) => {
            console.log(err);
        });       
}

// メールアドレスに認証メールを送信する
export const PostMail = ( subject:string, text:string, success_func:() => void, fail_func:()=>void, idea_id?:string, user_id?:string, mail?:string,) => {
    axios.post(process.env.REACT_APP_API_URL+'/mail/sendbyid', {idea_id: idea_id, user_id:user_id, mail: mail, subject: subject, text: text})
        .then(res => {
            if(res.data.result === "success"){
                success_func()
            }else if(res.data.result === "failed"){
                fail_func()
            }
        }).catch((err) => {
            console.log(err);
        });       
}
