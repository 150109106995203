import { useState, useEffect } from 'react';
import { useIsMobile } from './isMobile';

export const useRatio = (referenceWidth?: number) => {
    const isMobile = useIsMobile()
    // IPhone 14 ProMax、と俺のPCを基準値とする。
    const _referenceWidth = referenceWidth ?? (isMobile ? 430 : 1024);
    const [widthRatio, setWidthRatio] = useState(window.innerWidth / _referenceWidth);

    useEffect(() => {
        const updateWidthRatio = () => {
            setWidthRatio(window.innerWidth / _referenceWidth);
        };
        // Update the ratio on initial render
        updateWidthRatio();
        // Update the ratio when the window is resized
        window.addEventListener('resize', updateWidthRatio);
        // Cleanup event listener on component unmount
        return () => window.removeEventListener('resize', updateWidthRatio);
    }, [_referenceWidth]);

    return widthRatio;
};