import { Box, Typography, Card, Stack, Avatar, styled, Badge} from "@mui/material";
import React, { useEffect } from "react";
import { useState } from 'react';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { isMonday } from "date-fns";

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
      backgroundColor: '#44b700',
      color: '#44b700',
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      '&::after': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        animation: 'ripple 1.2s infinite ease-in-out',
        border: '1px solid currentColor',
        content: '""',
      },
    },
    '@keyframes ripple': {
      '0%': {
        transform: 'scale(.8)',
        opacity: 1,
      },
      '100%': {
        transform: 'scale(2.4)',
        opacity: 0,
      },
    },
  }));

export interface IdeaCardProps {
    profile_image_name?: string | null | undefined;
    profile_image_uri?: string | null | undefined;
    user_name: string;
    onClick: () => void;
    ratio?: number;
    radius: number;
    cursor?: string;

    isMobile?: boolean;
}

const UserIcon: React.FC<IdeaCardProps> = (props: IdeaCardProps) => {

    let ratio = props.isMobile ? 0.7: 1;
    if(props.ratio !== (null || undefined)){
        ratio = props.ratio;
    }
    
  return (
    <StyledBadge
        overlap="circular"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        variant="dot"
      >
        
    {props.profile_image_name != null && props.profile_image_name != undefined  ? 
        <img src={process.env.REACT_APP_PUBLIC_URL+"/images/"+props.profile_image_name} style={{ borderRadius: props.radius * ratio, width: props.radius * 2 *ratio, height: props.radius * 2 * ratio, cursor: props.cursor ?? "pointer", objectFit: "cover",objectPosition: "center"}}/>
    :
    props.profile_image_uri != null && props.profile_image_uri != undefined  ? 
        <img src={props.profile_image_uri} style={{ borderRadius: props.radius * ratio, width: props.radius * 2 *ratio, height: props.radius * 2 * ratio, cursor: props.cursor ?? "pointer",objectFit: "cover",objectPosition: "center"}}/>
    :   <Avatar sx={{cursor:props.cursor ?? "pointer",width: props.radius * 2 * ratio, height: props.radius * 2 * ratio, fontSize: props.radius *ratio}}>{props.user_name ? props.user_name[0] : null}</Avatar>}
    </StyledBadge>
  );
};

export default UserIcon;
