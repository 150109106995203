import React, { useState, useEffect, useContext } from 'react';
import { Stack, Typography, TextField, Button, InputAdornment, Tabs, Tab, Drawer, Divider, Input, IconButton } from '@mui/material';
import reidea_logo from "./../img/RE-IDEA.png"
import SearchIcon from '@mui/icons-material/Search';

import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import ProfilePopover from './Popover/profile';
import { useSearchParams } from "react-router-dom";

import { MyContext } from '../routes/router';
import UserIconAnchored from './User/iconAnchored';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import MailOutlineIcon from '@mui/icons-material/MailOutline';

import HeaderSP from './reidea_header_sp';
import { useIsMobile } from './isMobile';
import { simple } from '../interfaces/chat';
import { GetViewfindbysess } from './API/views';
import { useGetUsers } from './API/users';
import { UseQueryResult } from 'react-query';

const theme = createTheme({
  palette: {
      secondary: {
        main: '#383E86',
      },
      text: {
        secondary: '#AAAAAA', // タブが選択されていない状態のテキスト色
      },
  },
});


const Header: React.FC = () => {

  const { minWidth, user,openRegisterPopup, openLoginPopup, openBetaLimitPopup } = React.useContext(MyContext);
  const [searchValue, setSearchValue] = useState(""); // 入力された値を保持する状態
  const [anchorEL, setAnchorEL] = React.useState<HTMLElement | null>(null);
  const [anchorSearch, setAnchorSearch] = React.useState<HTMLElement | null>(null);

  const [searchParams] = useSearchParams();
  // registerIDという名前のuserID
  const registerID = searchParams.get("registerID");
  // APIから情報を取得
  const {data: provisional_user } : UseQueryResult<simple | null, Error> & { queryKey: string } = useGetUsers("users_simple", "simple", registerID ?? "none", );
  
  const [firstPath, setFirstPath] = useState<string>("");
  const [mode, setMode] = useState<number>(firstPath === "search" ? 0 : 1);

  useEffect(() => {
    const path = window.location.pathname;
    const firstSegment = path.split('/')[1];
    setFirstPath(firstSegment);
    setMode(firstSegment === "search" ? 0 : 1)
  }, []);

  useEffect(() => {
    // 仮登録しているユーザーが存在するならば、登録
    if(provisional_user?.user_id !== undefined){
      openRegisterPopup()
    }
  }, [registerID, provisional_user]);


  //モバイル端末かどうかを判定
  const isMobile = useIsMobile();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    if(newValue === 0){
      window.location.href="/search"
    }
    if(newValue === 1){
      window.location.href="/ideas"
    }
  };

  return(
    isMobile ? <HeaderSP/> :
    (<Stack alignItems={"center"} justifyContent={"space-between"} direction={"row"} minWidth={minWidth} sx={{height:"70px",top:0, backgroundColor:"white", position:"sticky", zIndex:10}} width={"100%"}>
        <Stack borderBottom={"1px solid rgba(220, 220, 220, 0.6)"} justifyContent={"space-between"} pl={6} pr={3} sx={{height:"100%", width:"100%"}}>
          <Stack mt={1} direction="row" justifyContent={"space-between"} alignItems={"center"} width={"100%"}>
            <Stack direction="row" spacing={3} alignItems={"center"} sx={{cursor:"pointer"}} onClick={() => {window.location.href="/"}}>
              <img src={reidea_logo} alt="" style={{height: "55px"}}/>
            </Stack>
            
            <Stack direction={"row"} spacing={5} height={"100%"}>
              {window.innerWidth > 1000 &&
                <ThemeProvider theme={theme}>
                  <Tabs
                      value={mode}
                      onChange={handleChange}
                      textColor="secondary"
                      indicatorColor="secondary"
                      sx={{
                        "& .MuiTabs-indicator": {
                          height: 6,
                        },
                      }}
                    >
                    <Tab value={0} label="コンテスト一覧" sx={{fontSize:14, fontWeight:"bold", color:"#383E86"}} />
                    <Tab value={1} label="IDEA一覧" sx={{fontSize:14, fontWeight:"bold", color:"#383E86"}} />
                  </Tabs>
                </ThemeProvider>
              }

          <Stack direction="row" alignItems={"center"} spacing={3} height={"100%"}>
            
            {user?.user_id === undefined &&
              <Button variant='outlined' onClick={() => {openLoginPopup()}} sx={{fontSize:12, fontWeight:"bold", color: "#383E86", borderRadius:"24px"}}>ログイン</Button>
            }
            {user?.user_id === undefined &&
                <Button variant='contained' onClick={()=>{openRegisterPopup()}} sx={{fontSize:12, fontWeight:"bold", backgroundColor: "#383E86", borderRadius:"24px"}}>新規会員登録</Button>
            }
            {user?.user_id !== undefined &&
                <UserIconAnchored user_name={user?.user_name ?? ""} onClick={() => {window.location.href="/"}}
                                  profile_image_name={user?.profile_image_name}
                                  profile_image_uri={user?.profile_image_uri}
                                  setAnchorEL={setAnchorEL}
                                  radius={24}/>
            }
          </Stack>
          <ProfilePopover user_name={user?.user_name ?? ""} profile_image_name={user?.profile_image_name ?? null} profile_image_uri={user?.profile_image_uri ?? null} user_type={user?.type ?? null} user_id={user?.user_id} anchorEL={anchorEL} setAnchorEL={setAnchorEL}/>
          </Stack>
        </Stack>
      </Stack>
      </Stack>)
    )
}

export default Header;