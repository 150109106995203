import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { UseMutationResult, UseQueryResult, useMutation, useQuery } from 'react-query';
import { simple } from '../../interfaces/user';

interface PutUserPayload {
    user_id: string | null;
    newValues: { [key: string]: string | Blob };
}

interface GetUserPayload {
    type: "simple" | "medium";
    user_id: string | null;
    query?: string 
}




// ---------- simple ------------

// GET /users/type/user_id or query
export const useGetUsers = <T,>(query_key: string, type: "simple" | "medium", user_id: string | null, query?: string): UseQueryResult<T, Error> & { queryKey: string } => {
    // users/ simple or medium (/) user_id or (?) query
    const API_END_POINT_PASS = `/users/${type ?? ""}${user_id ? "/"+user_id : ""}${query ? "?"+query : ""}`
    const QUERY_KEY = query_key;
    return {
      ...useQuery([QUERY_KEY, user_id], () => fetch(process.env.REACT_APP_API_URL+API_END_POINT_PASS,{credentials: 'include'}).then(res =>res.json())),
      queryKey:QUERY_KEY,
    };
}

// GET /users/type/user_id or query
export const GetUsers = (): UseMutationResult<void, Error, GetUserPayload> => {
    return useMutation(async ({ type, user_id, query }: GetUserPayload) => {
       
      const response = await fetch(`${process.env.REACT_APP_API_URL}/users/${type ?? ""}${user_id ? "/"+user_id : ""}${query ? "?"+query : ""}`, {
        method: 'GET',
        credentials: "include",
      });
  
      if (!response.ok) {
        throw new Error('Failed to delete idea');
      }

      return response.json();
    });
}


// PUT /ideas/idea_id
export const usePutUser = (): UseMutationResult<void, Error, PutUserPayload> => {
    return useMutation(async ({ user_id, newValues }: PutUserPayload) => {
        // newValuesで指定されているFile系の値を送信用に変換したりする
        const formData = new FormData();

        // "file" という名前のキーを除外した新しいオブジェクトを作成
        const updateFields: { [key: string]: any } = {};
        for(let key in newValues){
            // 画像ファイルは別途変換して、formDataにマッピングする
            if(key === "profile_image"){
                if(newValues[key] !== null){
                    var bin = atob((newValues[key] as string).replace(/^.*,/, ''));
                    var buffer = new Uint8Array(bin.length);
                    for (var i = 0; i < bin.length; i++) {
                        buffer[i] = bin.charCodeAt(i);
                    }
                    // 画像ファイルはformDataにそのままぶち込む
                    formData.append(key, new File([buffer.buffer], ".png", {type: "image/png"}));
                }
            }else{
                // その他変更したいカラムはupdateFieldsにぶち込む
                updateFields[key] = newValues[key];
            }
        }
      formData.append("updateFields", JSON.stringify(updateFields))


      const response = await fetch(`${process.env.REACT_APP_API_URL}/users/${user_id ?? ""}`, {
        method: 'PUT',
        credentials: "include",
        body: formData
      });
  
      if (!response.ok) {
        throw new Error('Failed to delete idea');
      }

      return response.json();
    });
};

// Logout
export const useLogout = () => {
    const API_END_POINT_PASS = '/logout'
    return useMutation(() => {
        return fetch(process.env.REACT_APP_API_URL+API_END_POINT_PASS,{credentials: 'include'}).then((res:any) =>{window.location.href="/"})
    });
};

// Login
export const useLogin = (success_func:() => void, failed_func:()=>void, mail:string, password:string, socket_id: string | undefined) => {
    return useMutation(() => {
        return axios.post(process.env.REACT_APP_API_URL+'/login',  {mail: mail,password: password, socket_id: socket_id,},{withCredentials: true})
      }, { onSuccess: (res: any) => {
        if(res.data.result === "success") {
            success_func()
        }else if(res.data.result === "failed"){
            failed_func()
        }
      } })
};

// PreSetLogin
export const usePreSetLogin = (success_func:() => void, failed_func:()=>void, socket_id: string | undefined) => {
  return useMutation(() => {
      return axios.get(process.env.REACT_APP_API_URL+'/login/preset?socket_id='+socket_id,  {withCredentials: true})
    }, { onSuccess: (res: any) => {
      if(res.data.result === "success") {
          success_func()
      }else if(res.data.result === "failed"){
          failed_func()
      }
    } })
};