import { useState, useEffect } from 'react';

export const useIsMobile = () => {
    const [isMobile, setIsMobile] = useState(false);
  
    useEffect(() => {
      const checkIfMobile = () => {
        const userAgent = navigator.userAgent;
        const isMobileDevice = /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
        const isScreenWidthSmall = window.innerWidth <= 1024;
        setIsMobile(isMobileDevice || isScreenWidthSmall);
      };
  
      // Check on initial render
      checkIfMobile();
  
      // Check when the window is resized
      window.addEventListener('resize', checkIfMobile);
  
      // Cleanup event listener on component unmount
      return () => window.removeEventListener('resize', checkIfMobile);
    }, []);
  
    return isMobile;
  };