import React, { useState, useEffect, useContext, useRef } from 'react';
import Box from '@mui/material/Box';
import { InputAdornment, IconButton, Typography, Button, Modal, Stack, TextField, Card, Avatar } from '@mui/material';
import Fade from '@mui/material/Fade';
import MoreButton from '../Buttons/more';
import PopupLoginError from './snsLoginError';
import { SetNewPasswordByMail } from '../API/code';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    height: 340,
    overflowY: "auto",
    backgroundColor: 'white',
    justifyContent: "center",
    alignItems: "center",
    borderRadius:5
};
const styleSP = {
    position: 'absolute',

    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "85%",
    height: 350,
    // overflowY: "scroll",
    backgroundColor: 'white',
    justifyContent: "center",
    alignItems: "center",
    borderRadius:5,
};

// Propsの型を定義
interface Props {
    handleClose: () => void;
    open: boolean;
    isMobile?: boolean;
    handleOpenRegister?: () => void
    setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}

const PopupSetNewPassword: React.FC<Props> = ({ handleClose, open, setOpen, isMobile, handleOpenRegister }) => {
   
    const [showRegisterErrorPopup, setShowRegisterErrorPopup] = useState<boolean>(false);
    const [mail, setMail] = useState<string>("");
    const [mailError, setMailError] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);


    useEffect(() => {
    }, []);

  return (
    <>
        <Modal open={open} onClose={handleClose} sx={{border:"none"}}>
            <Fade in={open}>
                <Stack sx={isMobile? styleSP : style}>
                    <Stack spacing={3} alignItems={"center"}>
                        <Typography sx={{fontWeight:"bold", color:"#363636", fontSize: 24}}>
                            パスワードの再設定
                        </Typography>
                        <Stack spacing={0.5}>
                            <TextField 
                                disabled={isLoading}
                                placeholder='登録しているメールアドレスを入力' 
                                sx={{width:"290px", backgroundColor:"white", borderRadius:"10px",}}
                                value={mail}
                                required
                                onChange={(event) => {setMail(event.target.value); setMailError(false)}}
                                inputProps={{style: {fontSize: 14, padding:10,borderRadius:"5px",  border:mailError ? "1px solid red" : "none"}}}/>
                            {mailError &&
                                <Typography textAlign={"right"} sx={{fontWeight:"bold", color:"red", fontSize: 12}}>
                                    アカウントが見つかりません
                                </Typography>
                            }
                            
                        </Stack>
                        
                        <MoreButton disabled={isLoading} fontSize='12px' height='36px'  loading={isLoading}
                            onClick={
                                ()=>{
                                    setIsLoading(true);
                                    SetNewPasswordByMail(mail, ()=>{setIsLoading(false); window.location.href="/"}, ()=>{setMailError(true); setIsLoading(false)})}} text='再設定用メールを受け取る' width='290px'/>


                    </Stack>
                    
                
                </Stack>
            </Fade>
        </Modal>
        <PopupLoginError open={showRegisterErrorPopup} handleClose={()=>{setShowRegisterErrorPopup(false)}} isMobile={isMobile} />
    </>
  );
};

export default PopupSetNewPassword;