import React, { useState, useEffect, ChangeEvent } from 'react';
import axios from 'axios';
import Stack from '@mui/material/Stack';
import { Grid, FormGroup, Checkbox, Card, Button, TextField, Typography,InputAdornment, Box } from '@mui/material';
import { useSearchParams } from "react-router-dom";
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import FormControlLabel from '@mui/material/FormControlLabel';
import { GetCategories } from '../API/categories';
import { useIsMobile } from '../isMobile';

interface Props {
    categories: (string | null)[]
    setCategories: (value:(string | null)[]) => void
    marginTop?: number;
    spacingX?: number;
    spacingY?: number;
    isMobile?: boolean;
    max?: number
    row?: number

    fontSize?: number
    defaultCategories: (string | null)[]
    disabled: boolean

    error?: boolean;
}

const FormController = (onChange:(event: ChangeEvent<HTMLInputElement>, checked: boolean) => void, label:string, selectedValues:(string | null)[], max:number, defaultCategories:(string | null)[], special_disabled:boolean, fontSize?:number) => {
  return(
      <FormControlLabel value={label}  sx={{ '& .MuiSvgIcon-root': { fontSize: fontSize ? fontSize-2 : 16 } }} control={<Checkbox sx={{padding:"6px",}} checked={special_disabled ? defaultCategories.includes(label) : selectedValues.includes(label)} disabled={special_disabled ? true :selectedValues.length < max ? false : !selectedValues.includes(label)} onChange={onChange} />} 
                        label={<span style={{ fontSize: fontSize ? fontSize : 18 }}>{label}</span>} />
  )
}


// 投稿ボタン
const LimitedCheckboxCategories: React.FC<Props> = (props) => {


  const [selectedValues, setSelectedValues] = useState<(string | null)[]>([]);

  React.useEffect(() => {
    setSelectedValues(props.categories)
  }, []);


  const handleSelectionChange = (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    const { value } = event.target;
    if(selectedValues.length >= (props.max ?? 1000) && !selectedValues.includes(value)) return;
    const index = selectedValues.indexOf(value);
    if (index === -1) {
      setSelectedValues([...selectedValues, value]); // Add to array if not already selected
      props.setCategories([...selectedValues, value])
    } else {
      const updatedValues = [...selectedValues];
      updatedValues.splice(index, 1); // Remove from array if already selected
      setSelectedValues(updatedValues);
      props.setCategories(updatedValues)
    }
  };
  
  const { data: categories, isValid: isValid_categories, setIsValidData: setIsValidData_categories }  = GetCategories();


  // 4つずつのカテゴリをスタックに分ける関数
  const createCategoryStacks = (row:number) => {
    const stacks = [];
    for (let i = 0; i <= Math.ceil(categories.length / row); i += 1) {
      const categorySlice = categories.slice((i-1)*row, i * row);
      stacks.push(
        <Stack spacing={props.spacingY ? props.spacingY : 0.5} key={i}>
          {categorySlice.map((category:{category_id:string, name:string}, index:number) => (
            FormController(handleSelectionChange, category.name, selectedValues, (props.max ?? 1000), props.defaultCategories,props.disabled, props.fontSize)
          ))}
        </Stack>
      );
    }
    return stacks;
  };


  if(categories == null){
    return null;
  }

  return (
    <Stack >
      <FormControl  sx={{ borderRadius:"10px", border: props.error ? "1px solid red" : "none"}}>
        <FormGroup row sx={{marginTop: props.marginTop ?? 0 , justifyContent:"flex-start"}} >
          <Stack direction={"row"} spacing={props.spacingX ? props.spacingX : 0.5}>
            {createCategoryStacks(props.row ?? 5)}
          </Stack>
        </FormGroup>
      </FormControl>

      {props.max !== undefined &&
        <Stack direction={"row"} justifyContent={"flex-end"}>
          <Typography sx={{fontSize: props.fontSize ? Number(props.fontSize)-3 : 7, color: selectedValues.length >= (props.max ?? 10000) ? "#e60000" : "grey"}}>
              
            {props.disabled ? 
              props.defaultCategories.length + "/" + props.max
            :
              selectedValues.length + "/" + props.max
            }
          </Typography>
        </Stack>
      }
    </Stack>

  );
}

export default LimitedCheckboxCategories;
