import React, { useState, useEffect, useContext, useRef } from 'react';
import Box from '@mui/material/Box';
import { InputAdornment, IconButton, Typography, Button, Modal, Stack, TextField, Card, Avatar } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Fade from '@mui/material/Fade';
import { MyContext } from '../../routes/router';
import MoreButton from '../Buttons/more';

const style = {
    position: 'absolute',

    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    height: 400,
    overflowY: "auto",
    backgroundColor: 'white',
    justifyContent: "center",
    alignItems: "center",
    borderRadius:5
};
const styleSP = {
    position: 'absolute',

    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "90%",
    height: 430,
    // overflowY: "scroll",
    backgroundColor: 'white',
    justifyContent: "center",
    alignItems: "center",
    borderRadius:5,
};

// Propsの型を定義
interface Props {
    handleClose: () => void;
    open: boolean;
    isMobile?: boolean;
  }

  const PopupRegisterError: React.FC<Props> = ({ handleClose, open, isMobile }) => {
   

  return (
    <Modal open={open} onClose={handleClose} sx={{border:"none"}}>
        <Fade in={open}>
                <Stack sx={isMobile ? styleSP : style}>
                    <Stack width={isMobile ? "95%" : "90%"} spacing={4} p={5}alignItems={"center"} sx={{backgroundColor: "white", borderRadius:"20px" }}>
                        <Typography sx={{fontWeight:"bold", color:"#363636", fontSize: 24}}>
                            アカウントの認証に失敗しました
                        </Typography>

                        <Typography sx={{fontWeight:"bold", color:"#363636", fontSize: 14}}>
                            誠に恐れ入りますが、他のアカウントをご利用いただくか、メールアドレスによる新規会員登録をお願い申し上げます。
                        </Typography>

                        <Stack spacing={2}>
                            <MoreButton text='新規会員登録' fontSize='16px' width={isMobile ? "150px" : "200px"} height='32px' onClick={()=>{window.location.href="/?register=true"}}/>
                            <MoreButton text='ログイン' fontSize='16px' width={isMobile ? "150px" : "200px"} height='32px' onClick={()=>{window.location.href="/?login=true"}}/>   
                        </Stack>
                    </Stack>
                </Stack>
        </Fade>
    </Modal>
  );
};

export default PopupRegisterError;