import { Routes, Route, useNavigate, useLocation } from "react-router-dom"; // 追加
import React, { useState, useEffect, createContext, useContext, lazy, Suspense, } from 'react';
import {io, Socket} from 'socket.io-client';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';
import { useSearchParams } from "react-router-dom";
import { useGetUsers } from "../components/API/users";
import Header from "../components/reidea_header";
import Footer from "../components/reidea_footer";
import NotFound from "../pages/not_found";
import SetPassword from "../pages/set_password";
import PrivacyPolicy from "../pages/documents/privacyPolicy";
import SpecifiedCommercialTransaction from "../pages/documents/specifiedCommercialTransaction";
import UserAggreement from "../pages/documents/userAgreement";
import PopupLoginForm from "../components/Popup/login";
import PopupRegisterForm from "../components/Popup/register";
import PopupBetaLimit from "../components/Popup/betaLimit";
import { simple } from "../interfaces/user";
import { UseQueryResult, useQueryClient } from "react-query";
import { useFlexRatio } from "../components/useFlexRatio";

// TODO CSRF対策を行う必要がある。バックエンドについても同様。

// クロスサイトリクエストフォージェリ(CSRF):
//   悪意のあるサイトが認証済みユーザーのコンテキストで不正なリクエストを送信し、認証されたユーザーの権限を持って行動する攻撃手法


// Lazy-loaded components
const Contest = lazy(() => import('../pages/contest'));
const Home = lazy(() => import('../pages/home'));
const Ideas = lazy(() => import('../pages/ideas'));
const SearchPCSP = lazy(() => import('../pages/search'));
const MyPage = lazy(() => import('../pages/mypage_details'));

const theme = createTheme({
  typography: {
    fontFamily: [
      'Hiragino Sans', // ヒラギノフォントを指定
      'Arial', // 代替フォント
      'sans-serif', // サンセリフフォント
    ].join(','),
  },
});

// Socketオブジェクトとユーザーオブジェクトの型を定義
interface SocketUserContext {
  minWidth: number;
  socket: Socket;
  user: any;
  ratio_iPad_PC: number;
  ratio_iPad_SP: number;
  isSP: boolean;
  isMobile: boolean;
  isLoading_user: boolean;
  mode: number;
  setMode: Dispatch<SetStateAction<number>>
  isLoading: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>
  openLoginPopup: () => void;
  openRegisterPopup: () => void;
  openBetaLimitPopup: () => void;
  startLoading: () => void;
  endLoading: () => void;
}

// Contextの初期値を設定する
export const MyContext = createContext<SocketUserContext>({ minWidth:768, ratio_iPad_PC: 1.0, ratio_iPad_SP:1.0, isMobile: false, isSP: false, socket: {} as Socket, user: null, isLoading_user:false, mode: 0, setMode: ()=>{}, isLoading:false, setIsLoading: ()=>{}, openLoginPopup: () => {}, openRegisterPopup: () => {}, openBetaLimitPopup: () => {}, startLoading: () => {}, endLoading: () => {}   });

// ルーターによりURLで分岐
function Router () {

  const queryClient = useQueryClient()

  const {ratio_iPad_PC: ratio_iPad_PC, ratio_iPad_SP: ratio_iPad_SP, isMobile:isMobile, isSP:isSP} = useFlexRatio()
  const [searchParams] = useSearchParams();
  // recruitID と projectID と mode のクエリを取得
  const [socket, setSocket] = useState<Socket | null>(null);
  const [mode, setMode] = useState<number>(0)
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showLoginPopup, setShowLoginPopup] = useState<boolean>(false);
  const [showRegisterPopup, setShowRegisterPopup] = useState<boolean>(false);
  const [showBetaLimitPopup, setShowBetaLimitPopup] = useState<boolean>(false);

  const openLoginPopup = () => {
    setShowLoginPopup(true);
  }

  const openRegisterPopup = () => {
    setShowRegisterPopup(true)
  }

  const openBetaLimitPopup = () => {
    setShowBetaLimitPopup(true)
  }


  const startLoading = () => {
    setIsLoading(true)
  }

  const endLoading = () => {
    setIsLoading(false)
  }

  // サイト全体で user 情報を共有する
  const { data: user, isLoading: isLoading_user, queryKey: user_queryKey }: UseQueryResult<simple | null, Error> & { queryKey: string } = useGetUsers("user_simple", "simple", "sess");

  const location = useLocation();

  useEffect(() => {
    setSocket(io(process.env.REACT_APP_SOCKET_URL+''));
    setIsLoading(true);
  }, []);

  // ユーザー情報の変更を即時で受け取る(ログインなど)
  useEffect(() => {
    if (socket !== null) {
      socket.off('changed');
  
      socket.on('changed', (message:any) => {
        console.log("changed")
        queryClient.invalidateQueries(user_queryKey);
      });
    }
  }, [socket]);


  // ソケットに自分の識別情報を送る
  useEffect(() => {
    if (socket !== null && user?.user_id !== undefined) {
      socket.emit("join", {user_id: user?.user_id, socket_id:socket.id})
    }
  }, [user, socket]);

  if(socket === null){
    return null;
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
        <MyContext.Provider value={{ minWidth:1024, ratio_iPad_PC, ratio_iPad_SP, isMobile, isSP, socket, user, isLoading_user, mode, setMode, isLoading, setIsLoading, openLoginPopup, openRegisterPopup, openBetaLimitPopup, startLoading, endLoading}}>
          {!isLoading &&
            <Header/>
          }
          <Suspense fallback={<></>}>
            <Routes>
                <Route path="/" element={<Home/>} />
                <Route path="/ideas" element={<Ideas/>} />
                <Route path="/contest" element={<Contest/>} />
                <Route path="/search" element={<SearchPCSP/>}/>

                <Route path="/mypage" element={<MyPage/>} />
                {/* <Route path="/projects" element={<Projects/>} /> */}
                <Route path="/set/password" element={<SetPassword />} /> 
                <Route path="*" element={<NotFound />} /> 
                <Route path="/privacyPolicy" element={<PrivacyPolicy/>} /> 
                <Route path="/specifiedCommercialTransaction" element={<SpecifiedCommercialTransaction/>}/>
                <Route path="/userAgreement" element={<UserAggreement/>} />
            </Routes>
            </Suspense>
          {!isLoading && location.pathname !== "/mypage" &&
            <Footer />
          }
          
          {/* ログインポップアップの用意 */}
          <PopupLoginForm open={showLoginPopup} handleClose={() => {setShowLoginPopup(false)}} setOpen={setShowLoginPopup} isMobile={isMobile}  handleOpenRegister={()=>{setShowLoginPopup(false);setShowRegisterPopup(true);}} />
          {/* 会員登録ポップアップの用意 */}
          <PopupRegisterForm open={showRegisterPopup} handleClose={()=>{setShowRegisterPopup(false);}} setOpen={setShowRegisterPopup}  handleOpenLogin={()=>{setShowRegisterPopup(false); setShowLoginPopup(true)}} />
          {/* β版制限ポップアップの用意 */}
          <PopupBetaLimit isMobile={isMobile} open={showBetaLimitPopup} handleClose={() => {setShowBetaLimitPopup(false)}}/> 

        </MyContext.Provider>
    </ThemeProvider>
  );
}

export default Router;
