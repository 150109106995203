import React, { useState, useEffect } from 'react';
import axios from 'axios';


// カテゴリ一覧情報を取得する
export const GetCategories = () => {
    const [data, setData] = useState<any>(null);
    const [isValid, setIsValidData] = useState(false);
    useEffect(() => {
        // validでないときデータを再リロードする。
        if (isValid) return;
        axios.get(process.env.REACT_APP_API_URL+'/categories/find')
            .then(response => {
                setData(response.data);
                setIsValidData(true);
            })
            .catch(error => {
                console.log(error);
            });
    }, [isValid]); // 空の依存配列を渡すことで、コンポーネントがマウントされた時に一度だけデータを取得する

    return {data, isValid, setIsValidData};
}
