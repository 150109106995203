import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { InputAdornment, Grid, IconButton, Typography, Button, Stack, TextField, Card, Avatar } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { TextareaAutosize } from '@mui/material';
import { makeStyles } from '@mui/material';
import LimitedMultilineOutlinedBordered from '../limited_multiline_outlined_bordered';

interface TagsProps {
    cells: number;
    value: string | null,
    interval: string,
    placeholders: string[],
    setValue: Dispatch<SetStateAction<string>>
    error?: boolean,
    border?: string,
    padding?: string,
    backgroundColor?: string;
    fontSize?: string;
    disabled?: boolean;
    width?: string;
    rows?:number;
    errorCheck?: (text:string)=>boolean
}

const MultiTextfieldsLimitedMultilineOutlinedBordered: React.FC<TagsProps> = ({ cells, value, setValue, placeholders, interval, error, border, padding, backgroundColor, fontSize, disabled, errorCheck, rows, width }) => {
    


    const [value1, setValue1] = useState<string>("")
    const [value2, setValue2] = useState<string>("")
    const [value3, setValue3] = useState<string>("")
    const [value4, setValue4] = useState<string>("")
    const [value5, setValue5] = useState<string>("")

    const values = [value1, value2, value3, value4, value5]
    const setValues = [setValue1, setValue2, setValue3, setValue4, setValue5]

    useEffect(()=>{
        setValue(value1+(value2 ? interval+value2 : "")+(value3 ? interval+value3 : "")+(value4 ? interval+value4 : "")+(value5 ? interval+value5 : ""))
    }, [value1, value2, value3, value4, value5])

    useEffect(()=>{
        for(let i=0; i <= (value?.split(interval)?.length ?? 0) ; i++){
            setValues[i](value?.split(interval)[i] as string)
        }
    }, [])

    if((values?.length ?? 0) > 5){
        return null;
    }

    return(
        <Stack direction={"row"} width={"100%"} justifyContent={"flex-start"}>
            <Stack direction={"row"} alignItems={"center"} >
                {Array.from({ length: cells }, (_, index) => index).map((_, index) => {
                    return (
                        <>
                            <Stack direction={"row"} ml={1} mr={1} width={width}>
                                <LimitedMultilineOutlinedBordered
                                    value={values[index]} 
                                    setValue={setValues[index]} 
                                    placeholder={placeholders[index]}
                                    border={border}
                                    width={width}
                                    padding={padding}
                                    backgroundColor={backgroundColor}
                                    fontSize={fontSize} />
                            </Stack>
                            {index+1 < cells && <Typography sx={{fontSize:"20px"}}>{interval}</Typography> }
                        </>
                    )
                })}
            </Stack>

        </Stack>
    )
    
}

export default MultiTextfieldsLimitedMultilineOutlinedBordered;
